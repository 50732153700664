<template>
    <div id="Acasa_item">
        <div v-if = !mobile class="item-wrapper">
            <div class="cta_main">
                <p id="cta_text">Agentie de <span id="watermelon">Marketing</span> Digital</p>
            </div>
            <div class="item-list" v-for="(item,index) in home_items" :key = "item.id" ref="item_list">
                <div class="item-actual" @mouseenter="changeVis(index)" @mouseleave="changeVis(index)">
                    <p :data-visibility = "item.d_vis" class="item-title">{{item.title}}</p>
                    <p :data-visibility = "item.d_vis" class="item-message" >{{item.message}}</p>
                </div>
            </div>
        </div>
        <div v-if = mobile class="item-wrapper">
            <div class="cta_main">
                <p id="cta_text">Agentie de <span id="watermelon">Marketing</span> Digital</p>
            </div>
            <div class="item-list" v-for="(item,index) in home_items" :key = "item.id" ref="item_list">
                <div class="item-actual" @click="changeVis(index)">
                    <p :data-visibility = "item.d_vis" class="item-title">{{item.title}}</p>
                    <p :data-visibility = "item.d_vis" class="item-message" >{{item.message}}</p>
                </div>
            </div>
        </div>
            <div v-if = !mobile id="main_hero_container">
            </div>
       
    </div>
</template>



<script>


export default {
    name: 'Acasa_item',
    mounted(){
         if(window.innerWidth < 651){
                this.mobile = true
            } else {this.mobile = false}
        window.addEventListener('resize',()=>{
            if(window.innerWidth < 651){
                this.mobile = true
            } else {this.mobile = false}
        })
    },
    beforeUnmount() {
        window.removeEventListener('resize',()=>{})
    },

    data(){
        return{
            home_items:[
                {title:'Preturi Accesibile', message:'Sustinem cresterea afacerii tale prin livrarea de servicii premium la preturi avantajoase.', d_vis:false},
                {title:'Rezultate Rapide', message:'Ne dorim ca asteptarile tale sa ajunga la realitate in cel mai scurt timp.', d_vis:false},
                {title:'Suport Permanent', message:'Iti oferim suportul nostru permanent in orice situatie printr-o comunicare eficienta.', d_vis:false},
                {title:'Acuratete in Targetare', message:'Reclamele create de noi vor ajunge la clientul potrivit in momentul potrivit.', d_vis:false},
                {title:'Raportari Permanente', message:'Vei fi informat in permanenta cu rezultatele si statusurile campaniilor.', d_vis:false},
                {title:'Servicii Variate', message:'Iti oferim o gama larga de servicii bazate pe cunostinte avansate de digital marketing.', d_vis:false},
            ],
            mobile:false
        }
    },
    methods:{
        changeVis(i){
            return this.home_items[i].d_vis = !this.home_items[i].d_vis;
        }
    },
    components:{
    }
}
</script>




<style scoped>
    #Acasa_item{
        display: flex;
        box-sizing: border-box;
        position:relative;
        margin-top: 10px;
        min-height: 85vh;
        margin-bottom: 10vh;
        /* height: fit-content; */
        width: 100%;
        backdrop-filter: blur(7px);
        border-radius: 3px;
        /* overflow-x: hidden; */
    }
    #main_hero_container{
        position:absolute;
        height:72vh;
        width:40vw;
        top:0;
        right:0;
        background-image: url("../../assets/watermelon_hero.png");
        background-size:contain;
        background-repeat: no-repeat;
        opacity: 0.8;
        /* filter: hue-rotate(200deg); */
        border-radius: 10px;
        opacity:1;
    }
    img{
        height:60vh;
        width:25vw;
        filter: sepia();
        transform: translateY(100%);
        
    }
    .item-wrapper{
        display: flex;
        position: relative;
        flex-direction: column;
        /* justify-content: flex-start;
        align-items: flex-start; */
        margin-top: 1rem;
        width: 45vw;
        height: 45vh;}
    .item-list{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 4rem;
        margin: 1rem 1rem;
        /* border: 1px solid white; */
        /* background-color: rgba(255,255,255,0.6); */
        /* background-color: var(--var-main-color); */
        border-radius: 10px;
    }
    .item-actual{
        display: flex;
        position: relative;
        width:100%;
        height: auto;
        align-items: center;
        justify-content: center;
    }
    .item-title{ 
        display: flex;
        justify-content: center;
        opacity: 0;
        transition: 0.5s ease-in-out;
        width: 100%; 
        height:100%;
        font-size: 1.5rem;
        font-weight: bold;
        color: white;
        /* text-shadow: 1px 1px green; */
        font-family: "Roboto"
    }
    .item-message{ 
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: 0.5s ease-in-out; 
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 1.3rem;
        color: white;
        /* text-shadow: 1px 1px green; */
    }
    .item-message[data-visibility="true"]{
        opacity: 1;
    }
    .item-title[data-visibility="false"]{
        opacity: 1;
    }
    .cta_main{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    #cta_text{
        font-family: "Roboto";
        font-size: 3.5rem;
        color: white;
    }
    #watermelon{
        color: red !important;
    }
    @media (max-width: 1750px){
        #main_hero_container{
            background-size:cover;
        }
    }
    @media (max-width: 1270px){
        #cta_text{
            font-size: 2.5rem;
        }
    }
    @media (max-width: 900px){
        .item-title{ 
            font-size: 1.2rem;
        }
        #cta_text{
            font-size: 2rem;
        }
    }
    @media (max-width: 650px){
        .item-wrapper{
            width:80vw;
        }
        .item-message{
            font-size: 1.2rem;
        }
    }
    @media (max-width: 480px){
         .item-message{
            font-size: 1rem;
        }
        .item-title{
            font-size: 1rem;
        }
    }
</style>