<template>
<div id ="over1">

<Main_Contact />


</div>
  
</template>

<script>

import Main_Contact from '../components/Contact.vue'

export default {
  name: 'Contact_page',
  data(){return{
  }},
  mounted() {
  },
  methods:{
  },
  components:{
    Main_Contact
  }
}


</script>

<style scoped>

#over1 {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  margin-top:12vh;
  width:100vw;
  height: 73vh;
  border-radius: 3px;
  padding: 0 1rem;
}

#over1::-webkit-scrollbar {
  display: none;
}

@media (max-width: 850px){
  #over1{
    height:63vh;
  }
}
</style>