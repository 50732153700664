<template>
  <div id="footer_main">
 <div class="copyright"><p>Copyright 2022 Watermelon</p></div>
  <div class="logos">
    <img src="../assets/contact_logos/fb_icon.png" alt="">
    <img src="../assets/contact_logos/insta_icon.png" alt="">
  </div>
  </div>
</template>

<script>
export default {
  name: 'Footer_main',
}
</script>

<style scoped>
#footer_main {
  position: fixed;
  height: 10vh;
  width: 99vw;
  background-color: rgba(255,255,255,0.7);
  bottom: 5px;
  margin-top: 15px;
  border-radius: 5px;
}
.copyright{
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 15px;
}
.logos{
  display: flex;
  height: 100%;
  width: 15%;
  align-items: center;
  justify-content: space-around;
}
img{
  height: 50px;
  width: 50px;
}

@media (max-width: 650px){
  .logos{
    width: 30%;
  }
      }
</style>
