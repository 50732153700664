<template>
    <Main_view />   
</template>
<script>
import Main_view from '../components/Main_view.vue'

export default {
    name: 'Main_viewport',
    components: {
        Main_view
    }
}
</script>
<style scoped>


</style>