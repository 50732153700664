
<template>
    <div id="Contact_item">
        <div class="contact_area main">
            <form class="main_contact">
                    <label for="email">Email</label>
                <input type="email" id="email" v-model="email" required>
                    <label for="subject">Subiect</label>
                <input type="text" id = "subject" v-model="subject" required>
                    <label for="message">Mesaj</label>
                <textarea id="message"  cols="30" rows="10" v-model="message" required></textarea>
                <button @click.prevent="submitForm">Submit</button>
                
            </form>
        </div>
        <div class="gdpr_area main"><p class="gdpr-text">Solicita gratuit o discutie cu specialistii <span id="watermelon">Watermelon</span> ! <br><br> Garantăm calitatea serviciilor oferite. Stabilim și urmăm îndeaproape strategia de conformare, în tot acest timp fiind partenerul de discuție care promovează protecția datelor.  </p> </div>
    </div>

</template>
<script>
// import axios from 'axios'



export default {
    name: 'Main_Contact',
    data(){
        return{
            email:'',
            message:'',
            subject:''            
        }
    },
    methods:{
        submitForm(){
            if(!this.validateEmail(this.email)){return}
            const data = JSON.stringify({email:this.email, message:this.message, subject:this.subject})
            console.log(data)
            const fd = new FormData();
            fd.append('bd', data)
            fetch('http://watermelon.ro/api/index.php',
            {
                method: "POST",
                body:fd
            })
        },
        validateEmail(email) {
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email)) {
                return true
            } else {
                alert("Email invalid!")
                return false
            }
        }
    },
    
}
</script>

<style scoped>
    #Contact_item{
        display: flex;
        box-sizing: border-box;
        position:relative;
        margin-top: 1rem;
        min-height: 100vh;
        margin-bottom: 10vh;
        /* height: fit-content; */
        width: 100%;
        backdrop-filter: blur(7px);
        border-radius: 3px;
        /* overflow-x: hidden; */
    }
    .main{
        position: relative;
        width: 50%;
        /* margin-top: 5rem; */
        margin-left: 25px;
    }
    .main_contact{
        display: flex;
        flex-direction: column;
    }
    input{
        width: 50%;
        margin: 20px;
        padding: 15px;
        background-color: rgba(0, 0, 0,0.8);
        border-radius: 15px;
        border: 0.5px solid grey;
        color: white;
    }
    textarea{
        margin: 20px;
        padding: 15px;
        color: white;
        background-color: rgba(0, 0, 0,0.8);
        border-radius: 15px;
        border: 0.5px solid grey;
        resize: none;
    }
    button{
        width: 6rem;
        height: 2.5rem;
        align-self: center;
        justify-self: center;
        color: white;
        background-color: rgba(0, 0, 0,0.9);
        border-radius: 15px;
        border: 0.5px solid grey;
    }
    label{
        color: white;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .gdpr_area{
        display: flex;
        color: white;
        justify-content: center;
        align-items: flex-start;
    }
    .gdpr-text{
        text-align: center;
        font-size: 1.5rem;
    }
    #watermelon{
        color: red;
        font-size: 1.7rem;
    }

    @media (max-width: 650px){
        #Contact_item{flex-direction: column-reverse;
            min-height: 115vh;}
        .main{width: 90%;}
        input{width: 100%;}
        textarea{width: 100%;}
        .gdpr-text{font-size: 1.5rem;}
      }
</style>
