<template>
<div id ="over1" v-bind:loaded="loaded">

  <Acasa_item />
  <Echipa_item />
  <Servicii_item />

</div>
  
</template>

<script>
import Servicii_item from './main_elements/Services.vue'
import Echipa_item from './main_elements/About.vue'
import Acasa_item from './main_elements/Home.vue'


export default {
  name: 'Main_view',
  data(){return{
    loaded:false
  }},
  mounted() {
    this.loaded = true
  },
  methods:{
    
  },

  components:{
    Servicii_item,
    Echipa_item,
    Acasa_item
  }
}


</script>

<style scoped>

#over1 {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  margin-top:12vh;
  width:100vw;
  height: 73vh;
  border-radius: 3px;
  padding: 0 1rem;
}

#over1::-webkit-scrollbar {
  display: none;
}

@media (max-width: 850px){
  #over1{
    height:68vh;
  }
}
</style>