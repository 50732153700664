<template>
    <div id="Echipa_item">
        <div class="about-text" id="t1"><p>Suntem o echipa tanara si motivata, iar agentia noastra a luat nastere din dorinta de a introduce cele mai inovative tehnici de marketing in Romania si nu numai. Reusim sa imbinam creativitatea si cunostintele solide de digital marketing pentru a-i ajuta pe clientii nostrii sa isi atinga obiectivul cu o investitie cat mai scazuta. </p></div>
        <div class="about-pic" id="pa"><img src="../../assets/stock1.webp" alt=""></div>
        <div class="about-pic" id="pt"><img src="../../assets/stock2.jpg" alt=""></div>
        <div class="about-text" id="t2"><p>Stim cat de importanta este comunicarea intr-un business, de aceea iti oferim suportul nostru permanent si iti garantam o comunicare cat mai eficienta.

In ziua de astazi, timpul este foarte important, de accea vom pune accent pe tehnici potrivite nevoilor afacerii tale care te vor ajuta sa iti atingi obiectivul cat mai repede.
</p></div>
    </div>
</template>



<script>
export default {
    name: 'Echipa_item',
}
</script>




<style scoped>
    #t1{grid-area: text-1;}
    #t2{grid-area: text-2;}
    #pa{grid-area: pic-a;}
    #pt{grid-area: pic-t;}


    #Echipa_item{
        display: grid;
        box-sizing: border-box;
        grid-auto-columns: 1fr;
        grid-template-areas: 'pic-a text-1'
         'text-2 pic-t';
        gap: 1rem;


        position:relative;
        margin-top: 10px;
        min-height: 73vh;
        margin-bottom: 10vh;
        width: 100%;
        backdrop-filter: blur(7px);
        border-radius: 3px;
        /* overflow-x: hidden; */
    }

    img{
        width: 300px;
        height: 300px;
        object-fit:contain;
        border-radius: 50%;
    }
    .about-pic {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;

    }
    .about-text{
        color: white;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
         font-size: 1.5rem;
        text-shadow: 1px 1px green;
        font-weight: bold;
    }

    



    @media (max-width: 770px){
        #Echipa_item{
            min-height: 120vh;
            grid-template-areas: 
            'pic-a' 
            'text-1'
            'pic-t'
            'text-2';
        }
        img{
             width: 250px;
            height: 250px;
        }
        .about-text{
            font-size: 1rem;
        }
    }
</style>