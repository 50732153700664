<template>
 <router-link id="itm" to="/" @click = "scroll_handler">{{title}}</router-link>
</template>

<script>

export default {
  name: 'Nav_item',
  props:{
    title: String,
  },
  mounted(){
      
      
    },
  data(){return{
    element:this.$props.title
  }},
  methods: {
    scroll_handler(){
        const element = document.querySelector("#" + this.$props.title + "_item")
        if(element == null){setTimeout(() => {
          this.scroll_handler()
        }, 50);}
        else{
           setTimeout(() => { 
          this.scroll_actual(element)
      }, 50); 
        }
    },

     scroll_actual(element){
        element.scrollIntoView({behavior: 'smooth'}) 
    }
  },

  components: {

  }

}


</script>

<style scoped>
    #itm {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        list-style: none;
        cursor: pointer;
        outline: none;
        -webkit-tap-highlight-color: transparent;
        text-decoration: none;
        margin: 0 20px;
        padding: 0 20px;
        color: white;
        font-weight: 900;
        font-size: 1.5rem;
        /* text-shadow: 1px 1px green; */
        font-family: "Roboto";
        /* background-color: rgba(179, 179, 179,0.5);
        border-radius: 10px; */
    }
    #itm:hover{
        color: red;
    }



     @media (max-width: 970px){
      #itm {
        font-size: 1rem;
        margin: 0 10px;
        padding: 0 10px;
      }

      @media (max-width: 650px){
        #itm {
          font-size: 1.5rem;
        margin: 0 20px;
        padding: 0 20px;
        
        }
      }
     }
</style>