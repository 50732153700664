import { createRouter, createWebHistory } from 'vue-router'
import Main_view from '../views/Main.vue'
import Main_Contact from '../views/Contact_page.vue'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main_view
  },
  {
    path: '/contact',
    name: 'Contact_page',
    component: Main_Contact
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
