<template>
    <div id="Servicii_item">
        <div v-if=!mobile class="all-services">
            <div  class="services-container" v-for = "(service,index) in services_providers" :key = "service.index" ref="service_container" @mouseenter="isActive(index)" @mouseleave="notIsActive(index)">
                <div class="services-title" ><h1>{{service.title}}</h1></div>
                <div class="services-logo" :data-visibility="service.d_vis"><img v-bind:src="imgURL(service.logo)" alt=""></div>
                <div class="services-description" :data-visibility="service.d_vis"><p>{{service.desc}}</p></div>
            </div>
        </div>
        <div v-if=mobile class="all-services">
            <div  class="services-container" v-for = "(service,index) in services_providers" :key = "service.index" ref="service_container" @click="mobActive(index)" >
                <div class="services-title" ><h1>{{service.title}}</h1></div>
                <div class="services-logo" :data-visibility="service.d_vis"><img v-bind:src="imgURL(service.logo)" alt=""></div>
                <div class="services-description" :data-visibility="service.d_vis"><p>{{service.desc}}</p></div>
            </div>
        </div>
    </div>
</template>


<script>
var images = require.context('../../assets/service_logos/', false, /\.png$|.jpg$/)

export default {
    name: 'Servicii_item',
    mounted(){ 
        
        if(window.innerWidth < 651){
                this.mobile = true
            } else {this.mobile = false}
        window.addEventListener('resize',()=>{
            if(window.innerWidth < 651){
                this.mobile = true
            } else {this.mobile = false}
        })

        setInterval(()=>{
            if(!this.mobile){this.autoChange()}
        }, 7000)
     },
    data(){
        return{
            services_providers: [
                {d_vis:true,title:'Google', desc:'Stim cat de important este ca anuntul tau sa apara pe una dintre primele pozitii atunci cand utilizatorii efectueaza cautari specifice, de aceea optimizam campaniile astfel incat anunturile sa se afiseze in top, pentru o parte cat mai insemnata din cautarile relevante.',logo:"googlelogo.png"},
                {d_vis:true,title:'Facebook', desc:'Facebook si Instagram au devenit cele mai cunoscute retele de socializare, milioane de potentiali client fiind activi constant. Noi iti garantam ca vom dezvolta campanii eficiente, care sa afiseze anunturi utilizatorilor interesati de produsele si serviciile tale, pentru a inregistra o performanta crescuta;',logo:"facebook_logo.png"},
                {d_vis:true,title:'Instagram', desc:'Facebook si Instagram au devenit cele mai cunoscute retele de socializare, milioane de potentiali client fiind activi constant. Noi iti garantam ca vom dezvolta campanii eficiente, care sa afiseze anunturi utilizatorilor interesati de produsele si serviciile tale, pentru a inregistra o performanta crescuta;',logo:"instagram_logo.png"},
                {d_vis:true,title:'Linkedin', desc:'Linkedin este canalul ideal pentru tine daca doresti sa ajungi la persoanele cu putere decizionala din cadrul altor companii si sa cresti notorietatea brand-ului tau;',logo:"linkedin_logo.png"},
                {d_vis:true,title:'Tik-Tok', desc:'Oferim servicii de promovare pe TikTok, astfel incat publicul tinta, activ pe acest canal sa afle cat mai multe informatii despre brand-ul tau si despre serviciile oferite;',logo:"tiktok_logo.png"},
                {d_vis:true,title:'Yahoo', desc:'Oferim servicii de promovare pe platforma Yahoo!, o platforma cu multi ani in domeniul stirilor dar si a comunicarii intre persoane.',logo:"yahoo_logo.png"},
                
            ],
            service_entered: false,
            last_num: 0,
            mobile:false
        }
    },
    computed:{
        
    },
    watch: {

    },
    methods: {
        imgURL: function(path) {
            return images('./' + path)
        },
        isActive(i){
            this.service_entered=true
            if(this.services_providers[i].d_vis == true){return this.services_providers[i].d_vis = !this.services_providers[i].d_vis}
            console.log(this.services_providers[i].d_vis)
            },
        notIsActive(i){
            this.service_entered=false
            if(this.services_providers[i].d_vis == false){return this.services_providers[i].d_vis = !this.services_providers[i].d_vis}
        },
        autoChange(){
            const services = this.services_providers
            const random_num = Math.floor(Math.random() * services.length)
            if(random_num == this.last_num){this.autoChange()
            return}
            if(this.service_entered == false){
                    this.services_providers[random_num].d_vis = !this.services_providers[random_num].d_vis
                    setTimeout(()=>{this.services_providers[random_num].d_vis = !this.services_providers[random_num].d_vis},5000)
                    this.last_num = random_num
                }
            
        },
        mobActive(i){
            return this.services_providers[i].d_vis = !this.services_providers[i].d_vis
        }
    }
}
</script>




<style scoped>
    #test1{color: white;}
    #Servicii_item{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        position: relative;
        margin-top: 10px;
        min-height: 73vh;
        margin-bottom: 10vh;
        /* height: fit-content; */
        width: 100%;
        backdrop-filter: blur(7px);
        border-radius: 3px;
        /* overflow-x: hidden; */
    }

    .all-services{
        height: 100%; 
        width: 100%;
        display: grid;
        gap: 1.5rem;
        grid-auto-columns: 1fr;
        grid-template-columns: repeat(3,1fr);
    }

    img{height:175px; width:175px;}

    .services-container{
       background-color: transparent;
       position: relative;
       border-radius: 15px;
       
    }
    .services-title{
        position: absolute;
        display: flex;
        justify-content: flex-end;
        bottom: 0;
        right: 0;
        color: white;
        background-color: rgba(51, 51, 51,0.1);
        width: 100%;
        padding-right: 1rem;
        border-radius: 15px;
    }
    .services-logo{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        transition: 1s  ease-in-out;
    }
    .services-description{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.3rem;
        padding: 0.5rem;
        opacity: 0;
        transition: 1s  ease-in-out;
        color: white;
        font-weight: bold;
    } 
    p{
        text-align: center;
        vertical-align:middle;
    }
    .services-description[data-visibility="false"]{
        opacity: 1;
    }
    .services-logo[data-visibility="false"]{
        opacity: 0;
    }

    @media (max-width: 1500px){
        #Servicii_item{
            min-height: 110vh;
        }
        .all-services{
            grid-template-columns: repeat(3,1fr);
        }
        img{height:140px; width:140px;}
    }
    @media (max-width: 1150px){
        #Servicii_item{
            min-height: 130vh;
        }
        .all-services{
            grid-template-columns: repeat(2,1fr);
        }
        img{height:120px; width:120px;}
        .services-description{
            font-size: 1.1rem;
        }
    }
    @media (max-width: 750px){
        #Servicii_item{
            min-height: 200vh;
        }
        .all-services{
            grid-template-columns: repeat(1,1fr);
        }
        img{height:120px; width:120px;}
        .services-description{
            font-size: 1.1rem;
        }
    }
    @media (max-width: 750px){
        #Servicii_item{
            min-height: 500vh;
        }
        .all-services{
            grid-template-columns: repeat(1,1fr);
        }
        img{height:100px; width:100px;}
        .services-description{
            font-size: 1.1rem;
        }
    }

    
</style>