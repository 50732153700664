<template>
        <nav :data-open="menu_open">
            <div id="logo_main">
                <img src="../assets/logo.png" alt="logo">
            </div>
            <img src="../assets/watermelon_icon.png" id="open_close_button" :data-color="data_color" alt="" @click="switchColor">
            <div id="menu_wrapper" :data-open="menu_open">
                
                <ul >
                    <Nav_item title="Acasa" />
                    <Nav_item title="Echipa" />
                    <Nav_item title="Servicii" />
                    <Contact_item title="Contact" />
                </ul>
            </div> 
        </nav>
</template>

<script>
import Nav_item from "./Nav_item"
import Contact_item from "./Contact_item"

export default {
  name: 'HeaderMain',
  components: {
    Nav_item,
    Contact_item
  },
  data(){
    return{
        data_color: true,
        menu_open: false
    }
  },
  methods:{
    switchColor(){
        this.data_color = !this.data_color
        this.menu_open = !this.menu_open
    }
  }
}
</script>

<style scoped>
    nav {
        display: flex;
        position: fixed;
        height: 10vh;
        width: 99vw;
        /* background-color: var(--var-main-color); */
        /* background-color: rgba(255,255,255,0.7); */
        backdrop-filter: blur(7px);
        border-radius: 15px;
        z-index: 10;
        align-items: center;
    }
    #menu_wrapper{
        display: flex;
        justify-content: space-around;
        width: 100%;
        overflow: hidden;
        border-radius: 5px;
        z-index: 1;
    }
    ul {
        display: flex;
        width: 75%;
        justify-content: space-around;
        align-items: center;
    }

    /* #logo_main {
        height: 50px;
    } */
    #logo_main>img {
        height: 75px;
        max-width: 150px;
        padding: 10px;
    }

    #open_close_button{
        display: none;
    }
  
    @media (max-width: 650px){
        #menu_wrapper{
            flex-direction: column;
            position: fixed;
            width: 0;
            height: 50vh;
            top:0;
            right:0;
            margin: 0 1vw 0 0;
            transition: 1s ease-in-out;
        }
        ul {
            height: 100%;
            width: 100%;
            flex-direction: column;
            /* background-color: var( --var-main-color-solid); */
            background-color: rgba(128, 128, 128, 0.9);
            align-items: center;
            justify-content: space-around;
            padding: 0;
            border-radius: 10px;
            z-index: 200;
        }
        nav{
            background-color: transparent;
            justify-content: flex-start;
        }
        #open_close_button{
            display: block;
            position: absolute;
            right: 1rem; 
            top: 1rem;
            z-index: 1500;
            height: 50px;
            width: 50px;
            transition: 1s ease-in-out;
        }
        #open_close_button[data-color="false"]{
            filter: grayscale();
            transform: rotate(180deg);

        }
        #menu_wrapper[data-open="true"]{
            width:50vw;

        }
        #itm{
            margin-top: 25px;
        }
    }

</style>