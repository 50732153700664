<template>
  <video autoplay muted loop id="video_bck">
    <source src="../assets/videos/main_bck.mp4" type="video/mp4">
  </video>
</template>

<script>


export default {
  name: 'Background_Main',
}
</script>

<style scoped>
  #video_bck {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  }
 
 @media (max-width: 650px){
  #video_bck {
    transform: translate(50%);
  }
 }

</style>