<template>
  <Background_Main />
  <HeaderMain />
  <Footer_main />
  <router-view/>
</template>

<script>
  import Background_Main from './components/Backgroundmain.vue'
  import HeaderMain from './components/Header.vue'
  import Footer_main from './components/Footer.vue'


  export default {
    name: 'App',
      components: {
      HeaderMain,
      Background_Main,
      Footer_main,

    }
  }
</script>

<style>
@import './assets/css/variables.css';
@import './assets/css/fonts.css';

nav a.router-link-exact-active {
  color: #42b983;
}

</style>
